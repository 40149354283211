<template>
<v-app>
    <TriggerManagement />
</v-app>
</template>

<script>
import TriggerManagement from '../components/TriggerManagement/TriggerManagement.vue'
import router from './../router/index'
import { mapState, mapActions } from 'vuex'
import { routeNames } from '../../utils/dataValidations'
export default {
  components: {
    TriggerManagement
  },
  computed: {
    ...mapState([
      'isAuth',
      'isProd'
    ])
  },
  mounted: function () {
    if (!this.isAuth) {
      router.push(routeNames.Home)
    }
    this.setDeploymentName(this.$route.query.platform)
    if (this.isProd === null){
      router.push(routeNames.Welcome)
    }
  },
  methods: {
    ...mapActions([
      'setDeploymentName'
    ])
  }
}
</script>
