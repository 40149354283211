<template>
  <div :class="['tabBlock', colorTheme]">

    <ul class="tabBlock-tabs">
      <li
        v-for="(tab, index) in tabs"
        :key="index"
        :aria-setsize="tabs.length"
        :aria-posinet="index + 1"
      >
        <a
           href="javascript:void(0);"
           class="tabBlock-tab"
           :class="active_tab === index ? 'is-active' : ''"
           :aria-selected="active_tab === index"
           @click="changeTab(index, tab.tab_title)"
        >
          <div class="tab_title"> {{ tab.tab_title }} </div>
        </a>
      </li>
    </ul>
  </div>
</template>

colorTheme: light/dark.

<script>
export default {
  data () {
    return {
      active_tab: 0
    }
  },
  props: {
    tabs: {
      type: Array
    },
    colorTheme: {
      type: String,
      default: 'dark'
    },
    vertical: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    changeTab (tabIndexValue, tabIndexTitle) {
      this.active_tab = tabIndexValue
      this.$emit('update:active_tab_title', tabIndexTitle)
    }
  }
}
</script>

<style lang="scss" scoped>
*, ::before, ::after {
  box-sizing: border-box;
}

.tabBlock-tabs {
  box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.20);
  border-radius: 5px;
}

.tab_title {
    font-family: IBM Plex Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 14px;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    // height: 54px;
}

.tabBlock svg {
  height: 1px;
}

$grey: #6E7191;
$border: #d8d8d8;

.tabBlock {
  &-tabs {
    list-style: none;
    margin: 0;
    padding: 0;

    &::after {
      clear: both;
      content: "";
      display: table;
    }
  }

  &-tab {
    min-height: 54px;
    font-family: IBM Plex Sans;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 14px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    float: none;
    flex-direction: column;
    font-weight: 600;
    margin-right: 10px;
    padding: 0.625rem 1.25rem;
    position: relative;
    transition: 0.1s ease-in-out;
    text-decoration: none;

    // box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.35);

    &::before,
    &::after {
      content: "";
      display: block;
      height: 4px;
      position: absolute;
      transition: 0.1s ease-in-out;
    }

    &::after {
      left: -2px;
      right: -2px;
      top: -2px;
    }

    &::after {
      bottom: -2px;
      left: 0;
      right: 0;
    }

    @media screen and (min-width: 700px) {
      padding-left: 2.5rem;
      padding-right: 2.5rem;
    }

    &.is-active {
      position: relative;
      border-style: solid;
      border-width: 0px 0px 0px 5px;
      border-radius: 5px;
      z-index: 1;
    }

    svg {
      height: 1.2rem;
      width: 1.2rem;
      // margin-right: 0.5rem;
      pointer-events: none;
      fill: currentcolor;
      margin-bottom: 8px;
    }

  }

  &-content {
    padding: 1.25rem;

    a {
      font-weight: 700;
      transition: color 200ms ease;
    }

    hr {
      // margin: 3rem 0;
      border: 1px solid transparent;
      border-radius: 50%;
      border-top-color: #1155CC;
    }
  }
}

// light theme style =====================================================

.light {
  .tabBlock-tab {
    background: #FFFFFF;
    border-color: $border;
    color: $grey;

    &::after {
      background-color: $grey;
    }

    &::after {
      background-color: transparent;
    }

    &:hover,
    &:focus {
      color: #1155CC;
    }

    .is-active {
      color: #1155CC;
      border-color: #1155CC;
      &::after {
        background-color: #1155CC;
       }
    }
  }
  .is-active {
    color: #1155CC;
    border-color: #1155CC;
    // background: rgba(#1155CC,0.05);
  }
}

// dark theme style =====================================================

.dark {
  .tabBlock-tab {
    background: #1155CC;
    // border-color: $border;
    color: #FFFFFF;

    &::after {
      background-color: #FFFFFF;
    }

    &::after {
      background-color: transparent;
    }

    &:hover,
    &:focus {
      color: #FFFFFF;
    }

    .is-active {
      color: #FFFFFF;
      border-color: #1155CC;
    }
  }
  .is-active {
    color: #FFFFFF;
    border-color: #FFFFFF;
  }
}

</style>
