<template>
  <v-app>
    <v-container class="pa-3 mx-auto mt-5 outerContainer">
      <v-row>
      <v-col md="12">
        <v-row no-gutters>
          <v-col md="2">
              <Tabs :tabs="tabsWithIcons" :active_tab_title.sync="KindOfTrigger" colorTheme="light"></Tabs>
          </v-col>
          <v-col md="10">
            <v-card>
              <v-card-title>
                <span class="heading1 font-weight-black"> Communication </span>
                <v-tooltip bottom content-class="toolTip">
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon class="ma-2" color="#A0A3BD" v-bind="attrs" v-on="on"> mdi-information </v-icon>
                  </template>
                  <span> {{ infoText }} </span>
                </v-tooltip>
                <v-spacer></v-spacer>
                <Button v-show="displayListSMS.length !== 0 || triggersListParsedSuccessfully == true" class="removeButtonPadding" label="Triggers Documentation" type="outlined" @click="loadSampleFile"></Button>
              </v-card-title>
              <div class="tableDescription">
              <v-divider></v-divider>
              <div>List of countries with active triggers</div>
              </div>
              <v-container v-if="displayListSMS.length !== 0" class="table_container">
                <v-data-table :headers="showHeaders" :items="displayListSMS" class="custom_table_design">
                  <template v-slot:item.LanguagesSMS="{ item }">
                    <v-tooltip bottom content-class="toolTip">
                      <template v-slot:activator="{ on, attrs }">
                        <div :class="getClassForLanguages(item.LanguageListSMS)" v-bind="attrs" v-on="on"> {{ item.DisplayLanguageListSMS }} </div>
                      </template>
                      <span> {{ item.LanguagesSMS }} </span>
                    </v-tooltip>
                  </template>

                  <template v-slot:item.LanguagesIVR="{ item }">
                    <v-tooltip bottom content-class="toolTip">
                      <template v-slot:activator="{ on, attrs }">
                        <div :class="getClassForLanguages(item.LanguageListIVR)" v-bind="attrs" v-on="on"> {{ item.DisplayLanguageListIVR }} </div>
                      </template>
                      <span class="toolTip"> {{ item.LanguagesIVR }} </span>
                    </v-tooltip>
                  </template>

                  <template v-slot:item.TriggersSMS="{ item }">
                    <v-tooltip bottom content-class="toolTip">
                      <template v-slot:activator="{ on, attrs }">
                        <div :class="getClassForNumberOfTriggers(item.TriggersSMS)" v-bind="attrs" v-on="on"> {{ item.TriggersSMS }} Active </div>
                      </template>
                      <span v-if="item.TriggersSMS > 0"> {{ item.ActiveListSMS }} </span>
                      <span v-else> No Active Triggers </span>
                    </v-tooltip>
                  </template>

                  <template v-slot:item.TriggersIVR="{ item }">
                    <v-tooltip bottom content-class="toolTip">
                      <template v-slot:activator="{ on, attrs }">
                        <div :class="getClassForNumberOfTriggers(item.TriggersIVR)" v-bind="attrs" v-on="on"> {{ item.TriggersIVR }} Active </div>
                      </template>
                      <span v-if="item.TriggersIVR > 0"> {{ item.ActiveListIVR }} </span>
                      <span v-else> No Active Triggers </span>
                    </v-tooltip>
                  </template>

                  <template v-slot:item.Edit="{ item }">
                    <v-icon middle @click="showPopUp(item.deploymentCode)" color="#1155CC"> mdi-pencil-box {{ item }}</v-icon>
                  </template>

                </v-data-table>
              </v-container>

              <v-container v-else-if="displayListSMS.length === 0 && triggersListParsedSuccessfully == false">
                <v-data-table  class="custom_table_design" loading loading-text="Loading... Please wait"> </v-data-table>
              </v-container>

              <v-container v-else-if="displayListSMS.length === 0 && triggersListParsedSuccessfully == true">
                <v-avatar class="mt-8" size="40"> <v-img src="../../assets/Vector.png"></v-img> </v-avatar>
                <v-card-text class="mt-2">
                  <div>No Triggers Found</div>
                  <div> "Add New Trigger" to add New Trigger</div>
                  <v-btn color='blue' class="mt-8"> Add New Trigger </v-btn>
                </v-card-text>
              </v-container>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
  <v-overlay :value="dialog">
    <split-screen-pop-up :dialog.sync="dialog" :triggerList="this.dialogList" :type="this.KindOfTrigger" :inactiveList="this.inactiveList" />
  </v-overlay>
  </v-app>
</template>

<script>
import Button from '../sharedcomponents/Button.vue'
import Tabs from '../sharedcomponents/VerticalTabs.vue'
import { mapState, mapActions } from 'vuex'
import { Headers, makeDeploymentTriggersList, getSampleFileDriveLink } from '../../../utils/utils'
import SplitScreenPopUp from './SplitScreenPopUp.vue'

export default {
  components: { SplitScreenPopUp, Button, Tabs },
  name: 'TriggerManagement',
  computed: {
    ...mapState([
      'listOfDeploymentsAndTriggers'
    ]),
    showHeaders () {
      if (this.KindOfTrigger === "SMS") {
        return Headers.SMS
      }
      return Headers.IVR
    }
  },
  data () {
    return {
      KindOfTrigger: 'SMS',
      displayListSMS: [],
      infoText: 'List of Deployment and Triggers',
      triggersListParsedSuccessfully: false,
      dialog: false,
      dialogList: {},
      inactiveList: [],
      simplifyDisplayList: makeDeploymentTriggersList,
      tabsWithIcons: [{ tab_title: 'SMS' }, { tab_title: 'IVR' }]
    }
  },
  beforeMount: function () {
    this.setDeploymentName(this.$route.query.platform)
    this.getTriggersAndLanguages()
  },
  watch: {
    KindOfTrigger: function (val) {
      if (val === "SMS") {
        this.displayListSMS.sort(function (a, b) {
          return b.TriggersSMS - a.TriggersSMS
        })
      } else {
        this.displayListSMS.sort(function (a, b) {
          return b.TriggersIVR - a.TriggersIVR
        })
      }
    }
  },
  methods: {
    ...mapActions([
      'setDeploymentName',
      'setListOfTriggersAndDeployments'
    ]),
    async getTriggersAndLanguages () {
      await this.setListOfTriggersAndDeployments({ data: null, path: '/v1/trigger' })
      var simplifiedList = await this.simplifyDisplayList(this.listOfDeploymentsAndTriggers)
      this.displayListSMS = simplifiedList[0]
      this.inactiveList = simplifiedList[1]
      this.triggersListParsedSuccessfully = true
    },
    showPopUp (deploymentCode) {
      this.dialog = true
      this.dialogList = this.listOfDeploymentsAndTriggers.find(function (deployments) {
        if (deployments.deploymentCode === deploymentCode) {
          return deployments
        }
      })
    },
    newTrigger () {
      this.$router.push({ name: 'addTriggers', query: { platform: this.$route.query.platform } })
    },
    getClassForLanguages (item) {
      if (item.length > 1) return "bottomeDottedUnderline"
      return ""
    },
    getClassForNumberOfTriggers (item) {
      if (item > 0) return "bottomeDottedUnderline"
      return ""
    },
    loadSampleFile () {
      const driveLink = getSampleFileDriveLink('TriggerManagement')
      window.open(driveLink, '_blank')
    }
  }
}
</script>

<style scoped>
.removeButtonPadding {
  height: 36px;
  padding: 0px 16px !important;
}
.custom_table_design{
  border-radius: 8px !important;
}
.table_container{
  padding: 20px;
}
.tableDescription {
  font-size: 12px;
  line-height: 16px;
  width: 97%;
  margin: auto;
  color: #6E7191;
  text-align: justify;
}
.tableDescription > div {
  padding-top: 6px;
}
.outerContainer {
  font-weight: normal;
  width: 70% !important;
  font-family: IBM Plex Sans !important;
  font-style: normal;
  font-size: 14px;
}
.heading1 {
  font-size: 20px;
  line-height: 32px;
  letter-spacing: 0.005em;
}
.bottomeDottedUnderline {
  border-bottom: 1px dashed rgba(17, 85, 204, 0.75);
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 14px;
  line-height: 157%;
  vertical-align: middle;
  letter-spacing: 0.0125em;
  color: rgba(17, 85, 204, 0.75);
}
.toolTip {
  background: #FCFCFC;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.16);
  border-radius: 8px;
  font-size: 12px;
  line-height: 183%;
  letter-spacing: 0.015em;
  color: #1E234C;
  max-width: 500px;
}
.v-data-table ::v-deep th {
  font-size: 14px !important;
  color: #1E234C !important;
  font-weight: 500;
  background-color: #F7F7FC;
  font-family: 'IBM Plex Sans';
  font-size: 14px;
  line-height: 157%;
  letter-spacing: 0.0125em;
}
.v-data-table ::v-deep td {
  font-size: 14px !important;
  color: #464A6B;
  line-height: 157% !important;
  letter-spacing: 0.0125em !important;
}
</style>
