<template>
<v-app>
  <v-layout row justify-end>
    <v-dialog fluid v-model="dialog" persistent transition="scroll-x-transitiion" max-width="40%">
      <div class="dialogHeading font-weight-black">
        {{triggerList.deploymentCode}} {{type}} Configuration
        <v-spacer></v-spacer>
        <v-btn icon @click.native="close"> <v-icon> mdi-close </v-icon> </v-btn>
      </div>
      <Tabs :tabs="Triggers" colorTheme="light" :active_tab_title.sync="activeInactive"></Tabs>
      <template v-for="item in getTypeOfTrigger()">
        <v-container :key="item.id">
          <v-card class="card" flat>
            <v-list-item three-line>
              <v-list-item-content>
                <div class="font-weight-black cardHeading"> {{ item.functionName }} </div>
                <div class="cardSubtitle"> {{ item.eventName }} </div>
              </v-list-item-content>

              <v-list-item-icon>
                <template v-if="item.isActive">
                  <Toggle v-model="item.isActive" :toggleSubmit="printSubmit" :submitObject="item" :useSubmitMethod="true" :confirmationDialogText="getModalText(item)" />
                </template>
                <template v-if="item.isActive || type === 'IVR'">
                  <v-btn @click="rerouteToEditPage(item)" class="iconPadding" icon><v-icon color="#1155CC"> mdi-pencil-box </v-icon></v-btn>
                </template>
                <v-btn @click="cloneTriggerWithinSameDeployment(item)" v-if="type === 'SMS' || item.isActive" class="iconPadding" icon><v-icon color="#1155CC"> mdi-alpha-c-box </v-icon></v-btn>
              </v-list-item-icon>
            </v-list-item>
            <v-container>

              <v-row class="triggerDetailsHeading ">
                <v-col md="3"> Trigger Type </v-col>
                <template v-if="item.cronTime !== ''"> <v-col md="3"> Time </v-col> </template>
                <v-col v-if="getLanguagesList(item.relatedTemplates).length > 0" md="6"> Languages </v-col>
              </v-row>

              <v-row class="triggerDetailsData font-weight-black">
                <v-col md="3"> {{ getEventTimeType(item.cronTime) }} </v-col>
                <template v-if="item.cronTime !== ''"> <v-col md="3"> {{ getTimeFromCron(item.cronTime) }} </v-col> </template>
                <template v-if="getLanguagesList(item.relatedTemplates).length > 0">
                  <v-col md="6">
                    <v-tooltip bottom content-class="toolTip">
                      <template v-slot:activator="{ on, attrs }">
                        <span v-if="getLanguagesList(item.relatedTemplates).length > 12" class="truncate" v-bind="attrs" v-on="on"> {{ getLanguagesList(item.relatedTemplates) }} </span>
                        <span v-else> {{ getLanguagesList(item.relatedTemplates) }} </span>
                      </template>
                      <span> {{ getLanguagesList(item.relatedTemplates) }} </span>
                    </v-tooltip>
                  </v-col>
                </template>
              </v-row>

            </v-container>
          </v-card>
        </v-container>
      </template>
    </v-dialog>
  </v-layout>
</v-app>
</template>

<script>

import Tabs from '../sharedcomponents/HorizontalTabs.vue'
import Toggle from '../sharedcomponents/Switch.vue'
import { initializePlatformMatomo } from '../../../utils/matomoTracking'
import { makeCommaSeperatedListOfLanguages, getTypeOfTrigger, changeCronToTime, getKindOfTrigger } from '../../../utils/utils.js'
import { mapActions } from 'vuex'

export default {
  components: {
    Toggle,
    Tabs
  },
  props: [
    'triggerList', 'type', 'inactiveList'
  ],
  data () {
    return {
      dialog: {
        default: false
      },
      Triggers: [{ tab_title: 'Active Triggers' }, { tab_title: 'Inactive Triggers' }],
      activeInactive: 'Active Triggers',
      activeTriggers: [],
      inActiveTriggers: [],
      getTimeFromCron: changeCronToTime,
      getEventTimeType: getTypeOfTrigger,
      getLanguagesList: makeCommaSeperatedListOfLanguages
    }
  },
  beforeMount () {
    this.makeSeperateActiveInactive()
  },
  mounted: function () {
    initializePlatformMatomo()
  },
  methods: {
    ...mapActions([
      'updateTriggerPostCall'
    ]),
    close () {
      this.$emit('update:dialog', false)
    },
    makeSeperateActiveInactive () {
      for (const item in this.triggerList.triggers) {
        if (this.type === getKindOfTrigger(this.triggerList.triggers[item]) && this.triggerList.triggers[item].isActive) {
          this.activeTriggers.push(this.triggerList.triggers[item])
        }
      }
      for (const item in this.inactiveList) {
        if (this.type === getKindOfTrigger(this.inactiveList[item]) && !this.inactiveList[item].isActive) {
          this.inActiveTriggers.push(this.inactiveList[item])
        }
      }
    },
    getTypeOfTrigger () {
      return this.activeInactive === 'Active Triggers' ? this.activeTriggers : this.inActiveTriggers
    },
    getModalText (item) {
      var confirmationDialogText1 = {}
      if (item.isActive === true) {
        confirmationDialogText1.title = "Deactivate"
      } else {
        confirmationDialogText1.title = "Activate"
      }
      confirmationDialogText1.dialogtext = 'Are you sure you want to ' + confirmationDialogText1.title + ' ' + item.eventName + ' Trigger?'
      confirmationDialogText1.dialogCancelText = 'Cancel'
      confirmationDialogText1.dialogSubmitText = 'Continue'
      confirmationDialogText1.title += ' Trigger'
      return confirmationDialogText1
    },
    getActiveState (item) {
      if (item.isActive === true) {
        return true
      } else return false
    },
    async printSubmit (item) {
      item.isActive = !item.isActive
      item.notificationType = this.type
      await this.updateTriggerPostCall({ data: item, path: "/v1/trigger" })
    },
    rerouteToEditPage (item) {
      var obj = {}
      item.hierarchyId = this.triggerList.hierarchyId
      obj.Trigger = item
      obj.Type = 'Edit'
      obj.DeploymentCode = this.triggerList.deploymentCode
      if (item.isActive === false && this.type === 'SMS') {
        obj.Type = 'Clone'
      }
      this.$router.push({ name: 'editTrigger', params: { obj }, query: { platform: this.$route.query.platform } })
    },
    cloneTriggerWithinSameDeployment (item) {
      var obj = {}
      item.hierarchyId = this.triggerList.hierarchyId
      obj.Trigger = item
      obj.Type = 'Clone'
      obj.DeploymentCode = this.triggerList.deploymentCode
      this.$router.push({ name: 'editTrigger', params: { obj }, query: { platform: this.$route.query.platform } })
    }
  }
}

</script>

<style scoped>
.iconPadding {
  padding-top: 5px;
  width: fit-content;
}
.triggerDetailsData {
  font-size: 16px;
  line-height: 162%;
  padding-left: 4%;
  text-align: left;
  color: #000000;
}
.triggerDetailsHeading {
  font-size: 12px;
  line-height: 183%;
  text-align: left;
  padding-left: 4%;
  color: #000000;
}
.card {
  height: 147px;
  background: #F7F7FC;
  border: 1px solid #D6D8E7;
  box-sizing: border-box;
  border-radius: 8px;
}
.cardSubtitle {
  position: absolute;
  height: 22px;
  left: 4%;
  top: 49%;
  font-size: 12px;
  vertical-align: top;
  line-height: 183%;
  letter-spacing: 0.015em;
  text-align: left;
  font-weight: 400;
  color: #000000;
}
.cardHeading {
  position: absolute;
  height: 50px;
  left: 4%;
  top: 10.88%;
  padding: 0px;
  font-size: 16px;
  line-height: 162%;
  display: flex;
  align-items: center;
  letter-spacing: 0.0125em;
  color: #1E234C;
}
.dialogHeading {
  height: 13vh;
  width: 100%;
  margin: auto;
  justify-content: center;
  padding: 0vw 1.5vw 0vw 1.5vw;
  font-size: 24px;
  line-height: 150%;
  display: flex;
  align-items: center;
  letter-spacing: 0.005em;
  color: #1E234C;
}
>>> .v-dialog {
  font-family: IBM Plex Sans !important;
  margin: 0px !important;
  height: 100vh !important;
  max-height: 100% !important;
  font-weight: 500;
  background-color: #FCFCFC !important;
  border-radius: 0px;
}
.v-dialog__content {
  align-items: flex-end;
  justify-content: flex-end;
  left: 0px;
  bottom: 0px;
}
.col {
  padding: 0px !important;
}
.truncate {
  display: inline-block;
  inline-size: inherit;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.toolTip {
  background: #FCFCFC;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.16);
  border-radius: 8px;
  font-size: 12px;
  line-height: 183%;
  letter-spacing: 0.015em;
  color: #1E234C;
  max-width: 500px;
}
</style>
